<template>
  <v-container fluid style="max-width: 600px;">
    <v-row id="content">
      <v-col cols="12" class="py-0">
        <v-row>
          <v-col cols="12">
            <h2>{{ $t('user.dashboard.logo.title') }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="5" class="padding-input" align="left">
            <div v-if="logo.imageID">
              <div v-if="userLogoUrl" class="image-controls">
                <v-btn
                  fab
                  x-small
                  light
                  class="image-controls-buttons"
                  @click="removeImage()"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-container fluid class="image-controls">
                  <v-row>
                    <v-col align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        class="image-controls-buttons"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </div>
            <div v-else class="pb-3">
              <v-btn
                depressed
                tile
                v-html="$t('user.dashboard.logo.addImageButton')"
                class="primary white--text"
                :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
                @click="addImage()"
              ></v-btn>
            </div>
            <img
              v-if="userLogoUrl"
              :src="userLogoUrl"
              class="rounded-card mt-1"
              height="50"
            />
          </v-col>
          <v-col cols="12" sm="7" class="mt-2" align="left" justify="center">
              {{ $t('user.dashboard.logo.note') }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <input
      type="file"
      style="display: none;"
      ref="fileInput"
      accept="image/png, image/jpeg"
      @change="fileSelected"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Logo",

  computed: {
    ...mapGetters([
      "user",
      "userLogoUrl",
      "userLogoImageMaxSize",
      "images",
      "axiosSource",
    ]),
  },

  data() {
    return {
      logo: {
        imageID: 0,
        fileName: "",
        image: null,
      },
    };
  },

  methods: {
    addImage() {
      this.$refs.fileInput.click();
    },
    clearFileInput() {
      this.logo.imageID = 0;
      this.logo.fileName = "";
      this.logo.image = null;
    },
    fileSelected(event) {
      const file = event.target.files[0];

      if (file.size > this.userLogoImageMaxSize * 1024) {
        const notification = {
          show: true,
          result: false,
          message:
            this.$t("user.dashboard.logo.maxImageSize") +
            ` ${this.userLogoImageMaxSize} KB. ` +
            this.$t("user.dashboard.logo.cannotBeUploaded"),
        };

        this.$store.dispatch("showSnackbar", notification);

        this.$refs.fileInput.value = null;

        return;
      }

      this.logo.image = file;

      this.$refs.fileInput.value = null;

      this.$store.dispatch("getImageUrl", this.logo.image).then(
        () => {
          this.updateImage();
        },
        (error) => {
          this.clearFileInput();

          // eslint-disable-next-line
          console.error("error", error);
        }
      );
    },
    updateImage() {
      this.itemOverlay = true;

      this.$store
        .dispatch("updateUserLogo", {
          imageID: this.logo.imageID,
          file: this.logo.image,
        })
        .then(
          (response) => {
            this.itemOverlay = false;

            const notification = {
              show: true,
              result: true,
              message: response.message,
            };

            this.$store.dispatch("showSnackbar", notification);
          },
          (error) => {
            this.itemOverlay = false;

            let errorMessage;
            if (
              this.axiosSource &&
              this.axiosSource.token &&
              this.axiosSource.token.reason &&
              this.axiosSource.token.reason.message
            ) {
              errorMessage = this.axiosSource.token.reason.message;
            } else {
              errorMessage = error.message;
            }

            const notification = {
              show: true,
              result: false,
              message: errorMessage,
            };

            this.$store.dispatch("showSnackbar", notification);

            // eslint-disable-next-line
            console.error("error", error);
          }
        );
    },
    removeImage() {
      const action = this.$t("user.dashboard.logo.removeImage");
      if (!confirm(action)) {
        return;
      }

      this.itemOverlay = true;

      this.$store.dispatch("removeUserLogo").then(
        (response) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.clearFileInput();
        },
        (error) => {
          this.itemOverlay = false;

          const notification = {
            show: true,
            result: false,
            message: error.message,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    setImage() {
      if (this.user && this.user.imageID > 0) {
        this.logo.imageID = this.user.imageID;
        this.logo.fileName = this.user.fileName;

        if (this.logo.fileName && this.userLogoUrl.length == 0) {
          this.$store.dispatch("getUserLogoSource", {
            fileName: this.logo.fileName,
          });
        }
      }
    },
  },

  watch: {
    userLogoUrl() {
      this.logo.imageID = this.user.imageID;
      this.logo.fileName = this.user.fileName;
    },
  },

  mounted() {
    this.setImage();
  },
};
</script>
