<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-row>
          <v-col>
            <UserDetails :user="item" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 pb-5" align="center" justify="center">
        <Logo />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-5" align="center" justify="center">
        <v-btn
          depressed
          tile
          v-html="$t('user.account.deleteAccountButton')"
          class="secondary white--text"
          :disabled="loading"
          :loading="deleting"
          @click="deleteAccountConfirm(item)"
        ></v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialog.show" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{
          $t("user.account.deleteAccountTitle")
        }}</v-card-title>

        <v-card-text>
          {{ $t("user.account.deleteAccountMessage") }}
        </v-card-text>

        <v-card-actions>
          <v-col cols="6" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('user.account.noButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="cancelDeleteAccount()"
            ></v-btn>
          </v-col>
          <v-col cols="6" class="px-1" align="right">
            <v-btn
              depressed
              tile
              v-html="$t('user.account.yesButton')"
              class="primary white--text full-width"
              :class="{ 'mt-3 mb-0': $vuetify.breakpoint.mdAndUp }"
              @click="deleteAccount()"
            ></v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

import UserDetails from "@/components/common/UserDetails";
import Logo from "@/components/user/Dashboard/Logo";

import { api } from "@/helpers/ApiCalls";

export default {
  name: "Account",

  components: {
    UserDetails,
    Logo,
  },

  data() {
    return {
      loading: false,
      deleting: false,
      confirmDialog: {
        show: false,
        item: null,
      },
    };
  },

  computed: {
    ...mapGetters(["user"]),
    item() {
      if (this.user) {
        return this.user;
      } else {
        return {};
      }
    },
  },

  methods: {
    getUser() {
      this.loading = true;
      this.$store.dispatch("getUser").then(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            setTimeout(function () {
              router.push({ name: "Home" }).catch(() => {});
            }, api.authErrorRedirectTimeout);
          }
        }
      );
    },
    deleteAccountProceed(item) {
      this.deleting = true;

      this.$store.dispatch("deleteAccount", item).then(
        (response) => {
          this.deleting = false;

          const notification = {
            show: true,
            result: true,
            message: response.message,
          };

          this.$store.dispatch("showSnackbar", notification);

          this.$store.dispatch("logoff");
          router.push({ name: "Home" }).catch(() => {});
        },
        (error) => {
          this.deleting = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);
        }
      );
    },
    deleteAccountConfirm(item) {
      this.confirmDialog.item = item;
      this.confirmDialog.show = true;
    },
    deleteAccount() {
      this.confirmDialog.show = false;
      this.deleteAccountProceed(this.confirmDialog.item);
    },
    cancelDeleteAccount() {
      this.confirmDialog.show = false;
    },
  },

  mounted() {
    this.getUser();
  },
};
</script>
